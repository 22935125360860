import * as React from "react"

import NasaEarth from "../../images/nasa-earth.jpg"
import PrimaryButtonLink from "../buttons/PrimaryButtonLink"
import SecondaryButtonLink from "../buttons/SecondaryButtonLink"

const Hero = () => {
  return (
    <div className="relative w-full shadow-xl sm:overflow-hidden">
      <div className="absolute inset-0">
        <img
          className="h-full w-full object-cover"
          src={NasaEarth}
          alt="NASA Photograph of the Earth at Night"
        />
        <div className="absolute inset-0 bg-gray-600 mix-blend-multiply opacity-80" />
      </div>
      <div className="relative px-4 sm:px-6 py-44 lg:px-8">
        <h1 className="text-center font-extrabold tracking-tight text-5xl lg:text-6xl">
          <span className="block text-white">Take control of your</span>
          <span className="block text-yellow-500">digital footprint</span>
        </h1>
        <p className="mt-6 max-w-lg mx-auto text-center text-xl font-semibold text-white sm:max-w-3xl">
          DigitalFootprint.earth brings the impact you have when using the
          internet to life through data analysis and interactive storytelling
          using maps. Get started with an account today or explore the impact of
          the top 1000 sites on the web.
        </p>
        <div className="mt-10 max-w-sm mx-auto sm:max-w-none sm:flex sm:justify-center">
          <div className="space-y-4 sm:space-y-0 sm:mx-auto sm:inline-grid sm:grid-cols-2 sm:gap-5">
            <SecondaryButtonLink to="/story">Explore</SecondaryButtonLink>
            <PrimaryButtonLink to="/login">Get Started</PrimaryButtonLink>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Hero
