import * as React from "react"

const TechCrunchLogo = () => {
  return (
    <p
      // style={{
      //   background: "linear-gradient(279deg,, 50%,#00a562)",
      //   color: "transparent",
      //   webkitBackgroundClip: "text",
      // }}
      className="text-3xl font-bold bg-gradient-to-r from-[#00d301] to-[#36c275] px-1"
    >
      TechCrunch Disrupt 2021
    </p>
  )
}

const EsriDeveloperSummitLogo = () => {
  return (
    <p className="text-3xl font-bold text-primary">
      2022 Esri Developer Summit
    </p>
  )
}

const AsSeenAt = () => {
  return (
    <div className="bg-primary py-12 sm:py-16">
      <div className="px-4 max-w-7xl mx-auto flex flex-col gap-y-8">
        <p className="text-center uppercase font-bold text-secondary">
          As Seen At
        </p>
        <div className="flex flex-col md:flex-row text-center items-center align-center gap-x-24 gap-y-16 mx-auto">
          <TechCrunchLogo />
          <EsriDeveloperSummitLogo />
        </div>
      </div>
    </div>
  )
}

export default AsSeenAt
