import * as React from "react"

import {
  CubeTransparentIcon,
  CursorClickIcon,
  LightBulbIcon,
  LightningBoltIcon,
  TrendingUpIcon,
} from "@heroicons/react/solid"

import NotYou from "../../images/NotYou.png"
import AuditPage from "../../images/AuditPage.png"

const transferFeatures = [
  {
    name: "Interactive Story Telling",
    description:
      "By using ESRI's ArcGIS API, DigitalFootprint.earth takes seamingly meaningless data and translates it into a tangible story that shows how serious the impact of the internet can be on our planet.",
    icon: CursorClickIcon,
  },
  {
    name: "Transparent Audit Trails",
    description:
      "By using DigitalFootprint.earth's web application and API, developers can consistently monitor their site's performance, CDN response time, and other metadata via our scoring system.",
    icon: CubeTransparentIcon,
  },
  {
    name: "Investigate and improve",
    description:
      "DigitalFootprint.earth investigates a site's hosting, CDN, performance and size to suggest improvement mechanisms and simple steps to make the internet a greener place.",
    icon: LightBulbIcon,
  },
]

// We also created a custom server-side-generated badge that can be displayed on a website to demonstrate to users that the developers are holding themselves accountable to ensuring their site is as green as can be.
const communicationFeatures = [
  {
    name: "Lightning Fast Audit Times",
    description:
      "With our new and improved audit system you can have scheduled audits and monitor multiple pages from the comfort of your dashboard - and all of this at lightning fast speeds",
    icon: LightningBoltIcon,
  },
  {
    name: "Track your footprint over time",
    description:
      "Get peace of mind knowing that we continuously monitor your site to let you know when issues come up.",
    icon: TrendingUpIcon,
  },
]

export default function Example() {
  return (
    <div className="bg-gray-50 dark:bg-gray-800 py-12 lg:py-24">
      <div className="relative max-w-xl mx-auto px-4 lg:max-w-7xl">
        <div className="relative lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
          <div className="relative ">
            <h3 className="text-2xl font-extrabold text-primary tracking-tight sm:text-3xl">
              What does it do?
            </h3>
            <p className="mt-3 text-lg text-secondary">
              DigitalFootprint.earth, uses mathematics and ESRI's mapping APIs
              to estimate and visualize the carbon footprint of the web. We go
              beyond the data to find insights that inspire change.
            </p>

            <dl className="mt-10 space-y-10">
              {transferFeatures.map((item, index) => (
                <div key={index} className="relative">
                  <dt>
                    <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-yellow-500 text-gray-900">
                      <item.icon className="h-6 w-6" aria-hidden="true" />
                    </div>
                    <p className="ml-16 text-lg leading-6 font-medium text-primary">
                      {item.name}
                    </p>
                  </dt>
                  <dd className="mt-2 ml-16 text-base text-secondary">
                    {item.description}
                  </dd>
                </div>
              ))}
            </dl>
          </div>

          <div
            className="mt-10 -mx-4 relative lg:mt-0"
            aria-hidden="true"
          >
            <img
              className="relative mx-auto rounded-md shadow-md"
              width={520}
              src={NotYou}
              alt="App Screenshot of Interactive Story Mode"
            />
          </div>
        </div>

        <div className="relative mt-12 sm:mt-16 lg:mt-24 ">
          <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
            <div className="lg:col-start-2">
              <h3 className="text-2xl font-extrabold text-primary tracking-tight sm:text-3xl">
                Always stay in the loop
              </h3>
              <p className="mt-3 text-lg text-secondary">
                Set up automated schedules so that you know that when your sites
                performance changes you'll be the first to know.
              </p>

              <dl className="mt-10 space-y-10">
                {communicationFeatures.map(item => (
                  <div key={item.id} className="relative">
                    <dt>
                      <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-yellow-500 text-gray-900">
                        <item.icon className="h-6 w-6" aria-hidden="true" />
                      </div>
                      <p className="ml-16 text-lg leading-6 font-medium text-primary">
                        {item.name}
                      </p>
                    </dt>
                    <dd className="mt-2 ml-16 text-base text-secondary">
                      {item.description}
                    </dd>
                  </div>
                ))}
              </dl>
            </div>

            <div className="mt-10 -mx-4 relative lg:mt-0 lg:col-start-1">
              <img
                className="relative mx-auto rounded-md shadow-md"
                width={560}
                src={AuditPage}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
