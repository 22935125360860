import * as React from "react"

const Stats = () => {
  return (
    <div className="px-4 max-w-7xl mx-auto pt-12 sm:pt-16">
      <div className="max-w-4xl mx-auto text-center flex flex-col">
        <h2 className="text-primary font-extrabold text-3xl sm:text-4xl">
          Made for developers, by developers.
        </h2>
        <p className="mt-3 text-secondary text-xl sm:mt-4">
          Our tool helps developers keep the environmental impact of their work
          at the front of their minds.
        </p>
      </div>

      <div className="mt-10 pb-12 sm:pb-16">
        <div className="relative">
          <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="max-w-4xl mx-auto">
              <dl className="rounded-lg bg-white shadow-lg sm:grid sm:grid-cols-3">
                <div className="flex flex-col border-b border-gray-100 p-6 text-center sm:border-0 sm:border-r">
                  <dt className="order-2 mt-2 text-lg leading-6 font-medium text-gray-500">
                    Webpages Analyzed
                  </dt>
                  <dd className="order-1 text-5xl font-extrabold text-indigo-600">
                    1174
                  </dd>
                </div>
                <div className="flex flex-col border-t border-b border-gray-100 p-6 text-center sm:border-0 sm:border-l sm:border-r">
                  <dt className="order-2 mt-2 text-lg leading-6 font-medium text-gray-500">
                    Use Green Hosting
                  </dt>
                  <dd className="order-1 text-5xl font-extrabold text-indigo-600">
                    44.94%
                  </dd>
                </div>
                <div className="flex flex-col border-t border-gray-100 p-6 text-center sm:border-0 sm:border-l">
                  <dt className="order-2 mt-2 text-lg leading-6 font-medium text-gray-500">
                    Badges Generated
                  </dt>
                  <dd className="order-1 text-5xl font-extrabold text-indigo-600">
                    25
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Stats
