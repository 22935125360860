import React from "react"
import AsSeenAt from "../components/landing/AsSeenAt"
import CallToAction from "../components/landing/CallToAction"
import FeatureSet from "../components/landing/FeatureSet"
import HeroSection from "../components/landing/Hero"
import Stats from "../components/landing/Stats"
import Layout from "../components/root/Layout"

const Home = () => {
  return (
    <Layout mainClassName="flex flex-col" isLanding>
      <HeroSection />
      <AsSeenAt />
      <FeatureSet />
      <Stats />
      <CallToAction />
    </Layout>
  )
}

export default Home
