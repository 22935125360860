import * as React from "react"

import PrimaryButtonLink from "../buttons/PrimaryButtonLink"
import ReportSample from "../../images/ReportSample.png"

const CallToAction = () => {
  return (
    <div className="py-10 bg-secondary">
      <div className="max-w-7xl mx-auto py-16 overflow-hidden lg:grid lg:grid-cols-2 space-y-10">
        <div className="flex flex-col justify-center items-center px-4 space-y-4">
          <div className="text-left w-full">
            <h2 className="text-3xl font-extrabold text-primary sm:text-4xl">
              Ready to dive in? <br />
              Get started for free.
            </h2>
          </div>
          <p className="text-lg text-primary">
            You can set up an account for free to start auditing the sites you
            love and care about. Let's put them to the test.
          </p>
          <div className="text-left w-full">
            <PrimaryButtonLink to="#">Sign up for free</PrimaryButtonLink>
          </div>
        </div>
        <div className="px-4">
          <img
            className="rounded-md shadow-md"
            src={ReportSample}
            alt="App screenshot"
          />
        </div>
      </div>
    </div>
  )
}

export default CallToAction
